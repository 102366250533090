@import '../../popups/popup-review/popup-review';

.reviews-screen {
   padding-top: var(--lg_sectionG);
   padding-bottom: calc(var(--sectionG) + 70px);
   background: var(--body_bg);

   .title-wrap {
      margin-bottom: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 50px;
   }

   .section-title {
      margin-bottom: 0;
   }

   .subtitle {
      max-width: 610px;
   }

   .swiper-slide {
      width: 100%;
      height: auto;
      display: flex;
      container-type: inline-size;
   }

   

   @media all and (max-width: 1070px) {
      .title-wrap {
         flex-direction: column;
         align-items: flex-start;
         gap: 0;
      }

      .section-title {
         margin-bottom: 30px;
      }
   }

   @media all and (max-width: 900px) {
      padding-top: 100px;
   }

   
}

.review {
   padding:clamp(25px, 5cqi, 35px) clamp(15px, 6cqi, 40px);
   background: var(--light);
   border-radius: var(--basicR);
   height: 100%;
   display: flex;
   flex-direction: column;
   width: 100%;

   &__title {
      margin-bottom: 25px;
      font-family: var(--title_font);
      font-size: clamp(21px, 4cqi, 22px);
      font-weight: 600;
      line-height: 130%;
      color: var(--dark);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
   }

   &__text-wrap {
      flex: 1 1 auto;
   }  

   .read-more {
      margin-top: 15px;
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      transition: all .2s ease;

      span {
         font-size: 11px;
         font-weight: 700;
         color: var(--dark);
         text-transform: uppercase;
         transition: inherit;
      }

      i {
         font-size: 11px;
         color: var(--dark);
         transition: inherit;
      }

      &:hover {
         span {
            color: var(--action);
         }

         i {
            transform: translateX(3px);
         }
      }
   }

   &__text {
      font-size: clamp(13px, 3cqi, 14px);
   }

   .author {
      margin-top: 35px;
      display: flex;
      align-items: center;
      gap: 15px;

      &__img {
         $size: 55px;

         width: $size;
         height: $size;
         flex: 0 0 $size;
         border-radius: var(--circleR);
         overflow: hidden;
         outline: 1px solid var(--dark);
         outline-offset: 3px;

         img {
            max-width: 100%;
            height: auto;
         }
      }

      &__name {
         margin-bottom: 5px;
         font-size: 17px;
         font-weight: 700;
         line-height: 100%;
         color: var(--dark);
      }

      &__position {
         font-size: 13px;
         font-weight: 400;
         line-height: 100%;
         color: var(--text_on_dark);
      }
   }
}

.popup-review {
   .review {
      padding: 0;
      background: none;

      .author {
         &__name {
            color: var(--light);
         }

         &__position {
            color: var(--text);
         }
      }
   }
}

@media all and (max-width: 310px) {
   .review {
      .author {
         flex-direction: column;
         align-items: flex-start;
      }
   }
}