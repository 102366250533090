.swiper-container {
   position: relative;
   
   .swiper, .swiper-wrapper {
      width: 100%;
   }
}

.swiper-arrows {
   margin-top: 35px;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 10px;
}

// Стрелки
.swiper-arrow {
   @include fillIconTheme (
      $size: 50px,
      $bg: var(--light),
      $iconSize: 18px,
      $bgH: var(--dark),
      $iconC: var(--dark),
      $iconCH: var(--action),
   );
   position: absolute;
   top: 40%;
   z-index: 1;

   // $size: 50px;

   // width: $size;
   // height: $size;
   // border-radius: var(--circleR);
   // border: 1px solid var(--light);
   // position: absolute;
   // top: 40%;
   // cursor: pointer;
   // z-index: 1;
   // transition: all .3s ease;

   // &:after {  
   //    content: '';
   //    position: absolute;
   //    top: 50%;
   //    left: 50%;
   //    transform: translate(-50%, -50%);
   //    width: 103%;
   //    height: 103%;
   //    background: var(--light);
   //    border-radius: var(--circleR);
   //    transition: inherit;
   // }

   &:before {
      font-family: var(--icon_font);
      font-size: 18px;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      transition: inherit;
   }

   // Правая
   &.next {
      right: -15px;
      transform: translateY(-50%);

      &:before { 
         content: '\e901'; 
         margin-left: 1px;
      }
   }

   // Левая
   &.prev {
      left: -15px;
      transform: translateY(-50%);

      &:before { 
         content: '\e900'; 
         margin-left: -1px;
      }
   }

   // Темная
   &.dark {
      $bg: var(--dark);
      $bgH: var(--light);

      border-color: $bg;

      &:after {
         background: $bg;
      }

      &:before {
         color: var(--light);
      }
   }

   // Статические
   &.static {
      position: relative;

      &.prev, &.next {
         transform: none;
         left: 0;
         right: 0;
      }
   }

   &:hover {
      // border-color: var(--dark);

      // &:after {
      //    background: var(--dark);
      //    width: 77%;
      //    height: 77%;
      // }

      &:before {
         color: var(--action);
      }
   }
}