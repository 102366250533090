.catalog-screen {
   padding-top: var(--sectionG);
   background: var(--body_bg);
   
   .title-wrap {
      margin-bottom: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
   }

   .section-title {
      margin-bottom: 0;
      text-align: right;
   }

   .catalog-wrap {
      display: flex;
      gap: 60px;
   }


   // Вкладки
   .catalog-tabs {
      width: 300px;
      flex: 0 0 300px;

      .tab {
         max-width: 390px;
         cursor: pointer;
         transition: all .3s ease;

         &__title {
            margin-bottom: 5px;
            position: relative;
            transition: inherit;

            span {
               font-size: 19px;
               font-weight: 600;
               line-height: 110%;
               color: var(--text_on_dark);
               position: relative;
               transition: inherit;
            }

            &:before {
               content: '';
               position: absolute;
               left: -5px;
               bottom: -2px;
               width: 0;
               height: 12px;
               background: var(--text_on_dark);
               border-radius: 2px;
               transition: inherit;
            }
         }

         &__product-count {
            font-size: 14px;
            font-weight: 400;
            color: var(--text_on_dark);
            transition: inherit;
         }

         &:hover, &.active {
            .tab__title span {
               color: var(--dark);
            }

            .tab__product-count {
               color: var(--text);
            }
         }

         &:hover {
            .tab__title:before {
               width: 80px;
            }
         }

         &.active {
            .tab__title:before {
               background: var(--action);
               width: 100px;
            }
         }

         &:not(:last-child) {
            margin-bottom: 25px;
         }
      }
   }


   // Swiper
   .catalog-container {
      width: calc(100% - 360px);
   }
   
   [id^="catalog-swiper"] {
      .product {
         $radius: var(--basicR);

         display: flex;
         transition: all .3s ease;
         container-name: productCard;
         container-type: inline-size;

         &-container {
            display: flex;
            flex-direction: column;
            border-radius: var(--basicR);
         }

         &-img {
            border-top-left-radius: $radius;
            border-top-right-radius: $radius;
            overflow: hidden;

            img {
               max-width: 100%;
               height: auto;
            }
         }

         &-info {
            padding: clamp(15px, 5cqi, 25px);
            display: flex;
            flex-direction: column;
            background: var(--light);
            // box-shadow: var(--product_info_white_block);
            position: relative;
            border-bottom-left-radius: $radius;
            border-bottom-right-radius: $radius;
         }

         &-title {
            margin-bottom: clamp(10px, 5cqi, 20px);
            font-size: clamp(14px, 6cqi, 20px);
            font-weight: 600;
            line-height: 120%;
            flex: 1 1 auto;
            min-height: clamp(35px, 5cqi, 48px);
         }

         &-descr {
            font-size: clamp(12px, 5cqi, 14px);
            font-weight: 400;
            line-height: 130%;
            color: var(--text);
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
         }

         &:hover {
            transform: translateY(-3px);
         }
      }
   }


   @media all and (max-width: 900px) {
      .catalog-wrap {
         display: block;
      }

      .catalog-tabs {
         margin-bottom: 60px;
         display: flex;
         gap: 40px;
         width: 100%;
         overflow-y: visible;
         overflow-x: auto;

         .tab {
            min-width: max-content;
         }
      }

      .catalog-container {
         width: 100%;
      }
   }

   @media all and (max-width: 740px) {
      .title-wrap {
         flex-direction: column;
         align-items: center;
         gap: 0;
      }

      .section-title {
         margin-bottom: 50px;
         text-align: center;
         order: 1;
      }

      .ui-btn {
         order: 2;
      }
   }
}