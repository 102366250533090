%viBorDericonHover {
   transform: translateY(-3px);
   border-color: transparent;

   &:before {
      width: 100%;
      height: 100%;
   }
}

// NOTE общие стили для круглых иконок
.vi-border-icon {
   $size: 90px;

   width: $size;
   height: $size;
   flex: 0 0 $size;
   border-radius: var(--circleR);
   border: 1px solid var(--dark);
   background: transparent;
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   transition: all .3s ease;

   i {
      font-size: 35px;
      color: var(--action);
      position: relative;
      transition: inherit;
   }

   &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 77%;
      height: 77%;
      background: var(--dark);
      border-radius: var(--circleR);
      transition: inherit;
   }

   &.action {
      $size: 65px;
      
      width: $size;
      height: $size;
      flex: 0 0 $size;
      border: 1px solid var(--border_dark);

      i { 
         font-size: 22px;
         color: var(--dark); 
      }

      &:before { background: var(--action); }

      @media all and (max-width: 340px) {
         $size: 50px;
      
         width: $size;
         height: $size;
         flex: 0 0 $size;

         i {
            font-size: 18px;
         }
      }
   }
}

// NOTE Hover-parent (родитель border-icon) - активатор hover border-icon
.hover_parent {
   &:hover {
      .vi-border-icon {
         @extend %viBorDericonHover;

         &.action {
            border-color: var(--light);
            transform: none;

            &:before {
               width: 77%;
               height: 77%;
               background: var(--light);
            }
         }
      }
   }
}

.about-screen {
   .vi-border-icon {
      margin-top: -40px;
   }
}



// TODO В секции с описаием АРТЕМИК блок с преимуществами
.artemix-screen {
   .vi-border-icon {
      $size: clamp(65px, 10cqi, 80px);

      width: $size;
      height: $size;
      flex: 0 0 $size;

      i {
         font-size: clamp(24px, 3.8cqi, 30px);
      }
   }
}




