.ui-burger {
   padding: 0;
   width: 30px;
   height: 20px;
   position: relative;
   display: none;
   cursor: pointer;
   align-items: center;

   &:before, &::after {
      content: '';
      position: absolute;
      width: 100%;
      background: var(--light);
   }

   &:before {
      top: 0;
      right: 0;
   }
   &::after {
      bottom: 0;
      left: 0;
   }

   span {
      display: inline-block;
      width: 100%;
      background: var(--action) ;
   }

   span, &:before, &:after {
      height: 2px;
      border-radius: 40px;
      transition: all 0.2s ease-out;
   }

   &.burger_sticky {
      display: none;
   }

   &.active, &:hover {
      &:before, &:after { width: 50%; }
   }

   @media all and (max-width: 850px) {
      display: flex;
   }
}