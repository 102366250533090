.socials {
   display: flex;
   align-items: center;
   gap: 20px;
   
   &__link {
      display: inline-flex;
      transition: 
         transform .2s ease,
         opacity .5s ease,
         visibility .5s ease,
         translate .5s ease;

      i {
         font-size: 17px;
         color: var(--light);
         transition: all .2s ease;
      }

      &.animate {
         opacity: 0;
         visibility: hidden;
         translate: 30px;
      }

      $j: .5s;
      @for $i from 1 through 2 {
         $j: $j + 0.1s;
         &:nth-child(#{$i}) {
            &.animation {
               opacity: 1;
               visibility: visible;
               translate: none;
               transition: 
                  transform .2s ease,
                  opacity .5s ease $j,
                  visibility .5s ease $j,
                  translate .5s ease $j;
            }
         }
      }

      &:hover {
         transform: translateY(-3px);

         i {
            color: var(--action);
         }
      }
   }

   &.words {
      margin-bottom: 30px;
      width: 100%;
      gap: clamp(50px, 5cqi, 70px);

      .socials__link {
         font-size: 16px;
         font-weight: 400;
         color: var(--light);
         position: relative;

         &:after {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 0;
            height: 1px;
            background: var(--light);
            transition: all .2s ease;
         }

         &:hover {
            color: var(--action);
            transform: none;

            &:after {
               width: 100%;
            }
         }


      }

      @media all and (max-width: 400px) {
        justify-content: center;
      }

      @media all and (max-height: 800px) {
         margin-top: 100px;
      }
   }
}


#popup-product {
   .socials {
      gap: 10px;
   }

   .socials__link {
      @include fillIconTheme (
         $size: 45px,
         $bg: var(--bg_on_dark),
         $iconSize: 18px,
         $bgH: var(--action),
         $iconC: var(--action),
         $iconCH: var(--dark),
      );

      border: 1px solid transparent;
      transition: all .3s ease;

      &:hover {
         transform: none;
      }
   }
}
