.action-screen {
   padding: 100px 0;
   background: url('../assets/img/action/action_bg.webp') no-repeat center / cover;

   .ui-container {
      container-type: inline-size;
   }

   .action-title {
      margin-bottom: 30px;
      font-size: clamp(34px, 12cqi, 50px);
      color: var(--light);
      max-width: 1120px;

      strong {
         font-weight: 700;                  
         color: var(--action);
      }
   }

   .ui-text {
      color: var(--text_on_dark);
      max-width: 700px;
   }

   .btn-group {
      margin-top: clamp(60px, 15cqi, 90px);
      display: flex;
      gap: 40px;
   }

   @media all and (max-width: 870px) {
      .action-title br {
         display: none;
      }
   }

   @media all and (max-width: 550px) {
      .btn-group {
         flex-direction: column;
         align-items: flex-start;
         gap: 20px;

         .ui-btn {
            max-width: 350px;
            width: 100%;
         }
      }
   }
}