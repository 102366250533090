.hero-screen {
   position: relative;
   height: 100dvh;
   background: url('../assets/img/hero/hero_bg.webp') no-repeat center / cover;

   .hero-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      &__container {
         height: 100%;
         display: flex;
         flex-direction: column;
         justify-content: center;
         width: 100%;
         container-type: inline-size;
      }

      &__titles {
         margin-top: 30px;
         display: flex;
         flex-direction: column;
         gap: 30px;
         font-family: var(--title_font);
      }

      &__title {
         font-size: clamp(50px, 10cqi, 75px);
         font-weight: 600;
         line-height: 110%;
         color: var(--light);
         text-transform: uppercase;
         transition: all .8s ease;

         strong {
            font-weight: 600;
            color: var(--action);
         }

         &.animate {
            opacity: 0;
            visibility: hidden;
            translate: 30px;

            &.animation {
               opacity: 1;
               visibility: visible;
               translate: none;
               transition-delay: .8s;
            }
         }
      }

      .decor-el {
         display: flex;
         align-items: center;
         gap: clamp(15px, 3cqi, 30px);
         position: relative;

         &.animate {
            opacity: 0;
            visibility: hidden;
            translate: 30px;
            transition: all .7s ease;

            &.animation {
               opacity: 1;
               visibility: visible;
               translate: none;

               &.top { transition-delay: .6s; }

               &.bottom { transition-delay: 1s; }
            }
         }

         strong {
            font-size: clamp(18px, 4cqi, 22px);
            font-weight: 200;
            color: var(--light);
            text-transform: uppercase;
         }

         &:before {
            content: '';
            display: block;
            width: clamp(1px, 15cqi, 160px);
            height: 1px;
            background: var(--light);
         }
      }

      // Кнопки
      .btn-group {
         margin-top: 90px;
         display: flex;
         flex-wrap: wrap;
         column-gap: 20px;
         row-gap: 15px;
      }

      .ui-btn {
         &.animate {
            opacity: 0;
            visibility: hidden;
            translate: 30px;

            $j: 1.1s;
            @for $i from 1 through 2 {
               $j: $j + 0.1s;
               
               &:nth-child(#{$i}) {
                  &.animation {
                     opacity: 1;
                     visibility: visible;
                     translate: none;
                     transition: 
                        transform .2s ease,
                        color .2s ease,
                        background-color .2s ease,
                        opacity .5s ease $j,
                        visibility .5s ease $j,
                        translate .5s ease $j;
                  }
               }
            }
         }
      }
   }

   .socials-wrap {
      width: 100%;
   }

   @media all and (max-height: 820px) {
      height: auto;

      .hero-content {
         &__container {
            margin-top: 150px;
         }
      }
   }

   @media all and (max-height: 820px) and (max-width: 400px) {
      .hero-content {
         &__container {
            margin-top: 120px;
         }
      }
   }

   @media all and (max-width: 400px) {
      .hero-content {
         .hero-content__title {
            font-size: clamp(35px, 15cqi, 50px);
         }

         .btn-group {
            justify-content: center;

            .ui-btn:nth-child(1) {
               width: 100%;
            }

            .ui-btn:nth-child(2) {
               max-width: 220px;
               width: 100%;
            }
         }
      }
   }
}