@import 'vars';
@import 'mixin';
@import 'functions';

// VI
@import '../../components/vi/vi-border-icon';
@import '../../components/vi/vi-fill-icon';
@import '../../components/vi/vi-swiper';

// Components
@import '../../components/header/header';
@import '../../components/footer/footer';
@import '../../components/socials/socials';
@import '../../components/menu/nav-menu/nav-menu';
@import '../../components/menu/mobile-menu/mobile-menu';
@import '../../components/contacts-links/contacts-links';
@import '../../components/popups/popup';

// UI
@import '../../components/ui/ui-preloader/ui-preloader';
@import '../../components/ui/ui-button/ui-button';
@import '../../components/ui/ui-burger/ui-burger';
@import '../../components/ui/ui-field/ui-field';
@import '../../components/ui/typography/typography';
@import '../../components/ui/ui-close/ui-close';
@import '../../components/ui/ui-scrollUp/ui-scrollUp';
@import '../../components/ui/ui-phone-link/ui-phone-link';



@import '../../components/screen/contact-screen/contact-screen';


* {
   -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      -webkit-tap-highlight-color: transparent;
   scroll-behavior: smooth;
   scrollbar-color: var(--action), var(--ui_dark) ; /* «цвет ползунка» «цвет полосы скроллбара» */
   scrollbar-width: thin ;/* толщина */
   box-sizing: border-box;
   font-weight: 400;
}

// Custom scrollbar for webKit
::-webkit-scrollbar {
   background: transparent;
   width: 5px;
   height: 5px;
}

::-webkit-scrollbar-thumb {
   background: var(--text_on_dark);
   border-radius: 50px;
}

::-webkit-scrollbar-corner {
   background: transparent;
}

html,body {
   scrollbar-gutter: stable;
   position: relative;
   // all: unset; // сбрасывыает все стили до значений по умолчанию
}

html {
   font-size: 16px;
}

body {
   background: var(--body_bg);
   position: relative;
   font-family: var(--text_font);

   &.no-scroll {
      overflow-y: hidden;
   }
}
   
html, body, main {
   width: 100%;
}

main {
   min-height: 100%;
}

i {
   display: inline-block;
   font-family: var(--icon_font);
}

// TODO ui-container
.ui-container {
   margin: 0 auto;
   padding: 0 20px;
   max-width: 1340px;

   &.lg {
      max-width: 1770px;
   }
}

// TODO overlay
.ui-overlay {
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: var(--overlay);
   opacity: 0;
   visibility: hidden;
   z-index: 1;
   transition: all .3s ease;

   &.active {
      opacity: 1;
      visibility: visible;
   }
}