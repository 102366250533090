.nav-menu {
   ul {
      display: flex;
      align-items: center;
      gap: clamp(10px, 2cqi, 20px);

      li {
         position: relative;
         transition: all .2s ease;

         &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0;
            background: var(--action);
            border-radius: 3px;
            transition: inherit;
         }  

         a {
            padding: 10px;
            font-size: clamp(11px, 4cqi, 13px);
            font-weight: 700;
            color: var(--light);
            text-transform: uppercase;
            display: inline-block;
            position: relative;
            z-index: 1;
            transition: inherit;
         }

         &:hover {
            &:after {
               height: 100%;
            }

            a {
               color: var(--dark);
            }
         }
      }
   }

   @media all and (max-width: 800px) {
      display: none;
   }
}