.contact-screen {
   padding-bottom: 100px;
   background: var(--dark);

   .ui-container {
      container: contactScreenC / inline-size;
   }

   .map {
      margin-top: -100px;
      width: 100%;
      height: 350px;
      background: var(--light);
      border: 10px solid var(--dark);
      border-radius: var(--mdR);

      iframe {
         border-radius: 10px;
      }
   }

   .title-wrap {
      margin: 60px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
   }

   .section-title {
      margin-bottom: 0;
   }

   .subtitle  {
      max-width: 690px;
      color: var(--text_on_dark);

      strong {
         font-weight: 700;
         color: var(--light);
      }
   }

   .contact-container {
      display: grid;
      grid-template: auto / 1fr 370px;
      gap: clamp(30px, 4cqi, 50px);
   }

   


   @media all and (max-width: 1100px) {
      .title-wrap {
         flex-direction: column;
         align-items: flex-start;
      }
   }

   @media all and (max-width: 1000px) {
      .contact-container {
         grid-template: auto / 1fr;
         gap: 60px;
      }
   }
}