%viFillIconHover {
   $size: 77%;

   &:after {
      width: $size;
      height: $size;
   }
}

@mixin fillIconTheme (
   $size: 60px,
   $bg: var(--light),
   $bgH: var(--light),
   $iconSize: 25px,
   $iconC: var(--dark),
   $iconCH: var(--dark),
   
) {
   width: $size;
   height: $size;
   flex: 0 0 $size;

   i {
      font-size: $iconSize;
      color:  $iconC;
   }

   &:after {
      background: $bg;
   }

   &:hover {
      border-color: $bgH;

      &:after {
         width: 77%;
         height: 77%;
         background: $bgH;
      }

      i {
         color: $iconCH;
      }
   }
}

// NOTE общие стили для круглых иконок
.vi-fill-icon {
   border-radius: var(--circleR);
   background: transparent;
   border: 1px solid transparent;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   position: relative;
   transition: all .3s ease;

   i {
      position: relative;
      z-index: 1;
      transition: inherit;
   }

   &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: var(--circleR);
      width: 103%;
      height: 103%;
      transition: inherit;
   }

   &.ui-scrollUp {
      @include fillIconTheme (
         $size: 45px,
         $iconSize: 16px,
         $bgH: var(--dark),
         $iconCH: var(--action),
      );
   }
}

