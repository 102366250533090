.contact-links {
   .contact-item {
      display: flex;
      align-items: center;
      gap: 10px;

      &:not(:last-child) {
         margin-bottom: 15px;
      }
   }

   .contact-item {
      span {
         font-family: var(--title_font);
         font-size: clamp(15px, 5cqi, 17px);
         font-weight: 600;
         line-height: 120%;
         color: var(--light);
         text-transform: uppercase;
         transition: inherit;
      }
   }

   a.contact-item {
      transition: all .2s ease;

      span {
         position: relative;
         white-space: nowrap;

         &:before {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 0;
            height: 1px;
            background: var(--light);
            transition: inherit;
         }
      }

      &:hover {
         span {
            color: var(--action);

            &:before {
               width: 100%;
            }
         }
      }
   }

   &.contactsScreenLinks {
      .contact-item {
         gap: 15px;

         span {
            font-size: 20px;
         }
      }
   }

   @media all and (max-width: 1000px) {
      &.contactsScreenLinks {
         display: flex;
         align-items: center;
         // justify-content: space-between;
         flex-wrap: wrap;
         column-gap: 60px;
         row-gap: 15px;
   
         .contact-item {
            span {
               font-size: 17px;
            }
   
            &:not(:last-child) {
               margin-bottom: 0;
            }
         }
      }  
   }

   @media all and (max-width: 840px) {
      &.contactsScreenLinks {
         justify-content: flex-start;
         flex-wrap: wrap;
      }
   }

   @media all and (max-width: 640px) {
      &.contactsScreenLinks {
         flex-direction: column;
         align-items: flex-start;

         .contact-item {
            span {
               font-size: clamp(15px, 5cqi, 20px);
            }
         }
      }
   }
}