.ui-btn {
   padding: 10px 40px;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   gap: 30px;
   min-height: 60px;
   border-radius: var(--controlR);
   font-size: 15px;
   font-weight: 700;
   text-transform: uppercase;
   white-space: nowrap;
   transition: 
      transform .2s ease,
      color .2s ease,
      background-color .2s ease,
      opacity .5s ease,
      visibility .5s ease,
      translate .5s ease;

   i {
      font-size: 17px;
      color: var(--light);
      transition: inherit;
   }

   &:hover {
      transform: translateY(-3px);

      i {
         color: var(--action);
         transform: translateX(7px);
      }
   }

   &.btn_product {
      padding: 14px 22px;
      font-size: 13px;
      color: var(--light);
      background: var(--dark);
      box-shadow: var(--btn_dark);
      position: absolute;
      right: 15px;
      top: -35px;
      min-height: auto;

      &:hover {
         color: var(--action);
         background: var(--border_dark);
      }
   }

   &.btn_action {
      background: var(--action);
      color: var(--dark);

      &:hover {
         background: var(--action_hover);
      }
   }

   &.btn_dark {
      color: var(--light);
      background: var(--dark);
      box-shadow: var(--btn_dark);

      &:hover {
         background: var(--border_dark);
      }
   }

   &.btn_outline {
      background: transparent;
      border: 1px solid var(--text_on_dark);
      color: var(--light);

      &:hover {
         background: var(--light);
         color: var(--dark);
      }
   }

   @media all and (max-width: 400px) {
      padding: 10px 30px;
      min-height: 55px;
      gap: 20px;

      i {
         font-size: 14px;
      }
   }
}